import React, {useEffect, useState} from "react";
import useProfile from "../../../react-query-hooks/useProfile";
import {useAuthState} from "../../../context/UserAuthentication";
import {IItemPathToRoute} from "../../../models/item.model";
import {useHistory} from "react-router-dom";
import {INearestLocationQueryParams} from "../../../models/location.model";
import VisitorDeliveryAddress from "./VisitorDeliveryAddress";
import {useAppDispatch, useAppSelector} from "../../../redux/store/store";
import { locationMenuService } from "../../../services";
import {isGuestUser} from "../../../helpers/helperMethods";
import { updateIsOrderTypeSelected, updateOrderDetails, updateOrderType } from "../../../redux/slices/cartSlice";
import { setLocation, useStoreLocation } from "../../../context/StoreLocation";
import { GUEST_USER } from "../../../constants";
import useSyncCart from "../../../hooks/useSyncCart";
import UserAddressDropdown from "./UserAddressDropdown";

interface IOrderDeliveryTab {
  itemPath: IItemPathToRoute;
  setShowOrderTypeGrid: (boolean) => void;
  handleOrderTypeModal: () => void;
  setIsNearestStoreAvailable: (isNearest) => void,
  isNearestStoreAvailable: boolean,
  showItemDeliveryDateTime?: boolean,
  handleShowDeliveryMethodModal?: (boolean) => void,
  saveSelectedOrderType?: string,
  handleOrderType?: (addressType: string ) => void;
  isItMobile: boolean;
  isCheckout: boolean;
  showOrderTypeGrid: boolean,
}

export const OrderDeliveryTab = ({
  setShowOrderTypeGrid,
  handleOrderTypeModal,
  setIsNearestStoreAvailable,
  isNearestStoreAvailable,
  showItemDeliveryDateTime,
  handleShowDeliveryMethodModal,
  saveSelectedOrderType,
  isItMobile,
  isCheckout,
  itemPath,
  showOrderTypeGrid
}: IOrderDeliveryTab) => {
  
  const { syncCart, loading: syncingCart } = useSyncCart();
  const orderTypeDispatch = useAppDispatch();
  const { authInfo } = useAuthState();
  const history = useHistory();
  const [userAddresses, setUserAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [isFindingNearest , setIsFindingNearest] = useState<boolean>(false);

  const {dispatch: locationDispatch } = useStoreLocation()
  const selectedDeliveryAddress = useAppSelector((state) => state.cart?.orderDetails?.delivery_address || "");
  const [newAddedAddressForDelivery, setNewAddedAddressForDelivery] = useState(
      selectedDeliveryAddress ?
          {value: selectedDeliveryAddress.address_name, label: selectedDeliveryAddress.address_name, id: selectedDeliveryAddress.id,
            isDefault: selectedDeliveryAddress.is_default,street: selectedDeliveryAddress.street_name, ...selectedDeliveryAddress}
          : null
  )
  const isGuestAddressSelected = selectedDeliveryAddress?.isGuestAddress;
  const showNewAddressForm = () =>{
    if (isGuestAddressSelected) return false;
    else return isGuestUser(authInfo) || !userAddresses?.length;
  }
  const [addGuestAddress, setAddGuestAddress] = useState<boolean>(showNewAddressForm());

  useEffect(() => {
    setAddGuestAddress(showNewAddressForm())
  }, [userAddresses]);
  
  const {
    isFetching,
    data: userProfileData,
  } = useProfile(authInfo.userId, authInfo.type);

  const checkNearestStoreLocation = async (address) => {
    setIsFindingNearest(true)
    const payload : INearestLocationQueryParams = {
      delivery: true,
      city: address.city,
      state: address.state,
      street: address.street,
      zipcode: address.zipcode,
      delivery_address: authInfo.type === GUEST_USER ? address?.fullAddress ?? address?.full_address : address?.full_address 
    }
    if( isCheckout ) payload.is_checkout = 1
    const response = await locationMenuService.getNearbyLocations(payload);
    if(response?.data?.data[0]) {
      setIsNearestStoreAvailable(true)
      setIsFindingNearest(false)
      return response.data.data[0]
    } else {
      setIsNearestStoreAvailable(false)
      setIsFindingNearest(false)
      return null
    }
  }

  const closeNewAddressForm = () => {
    setIsNearestStoreAvailable(true);
    setShowOrderTypeGrid(true)
    setAddGuestAddress(false)
  }
  const setAddress = (address) => {
    setSelectedAddress(address)
  }

  const handleLocation = (nearestLoc, deliveryAddress ) => {
      orderTypeDispatch(updateOrderDetails({"delivery_address" : deliveryAddress}))
      orderTypeDispatch(updateIsOrderTypeSelected(true))
      setLocation(locationDispatch, nearestLoc);
      handleOrderTypeModal()
      if(saveSelectedOrderType) {
        orderTypeDispatch(updateOrderType(saveSelectedOrderType));
      }
      if(showItemDeliveryDateTime || isCheckout) {
        handleShowDeliveryMethodModal(true)
      } 
      if(itemPath === null){
        history.go(0)
      }else{
        history.push(itemPath)
      }
  }

  const handleFormSubmission = async (values, {setSubmitting}) => {
    if (isGuestAddressSelected) {
      handleOrderTypeModal() 
      return;
    }
    setSubmitting(true)
    const response = await checkNearestStoreLocation(selectedAddress)
    if(!response) {
      setSubmitting(false)
    } else {
        handleLocation(response, selectedAddress)
        setSubmitting(false)
      }
    }

    const getSelectedDeliveryAddress = (addresses) => {
      //this check verifies if the adddress is being pre selected from Dropdown or the signed-in user added the temporary adddress
      if (newAddedAddressForDelivery) {
        return addresses.find(addr => addr.id === newAddedAddressForDelivery.id) || newAddedAddressForDelivery;
      }
      return addresses.find(addr => addr.isDefault === 1);
    };

  useEffect(() => {
    if(!isGuestUser(authInfo)) {
      if(!isFetching && userProfileData?.addresses?.length > 0) {
        const allAddressArray = userProfileData.addresses
                .map((address) => ({value: address.address_name, label: address.address_name, id: address.id, isDefault: address.is_default,street: address.street_name, ...address}))
        setUserAddresses(allAddressArray)
        setSelectedAddress(getSelectedDeliveryAddress(allAddressArray));
      }
    }
    else if(newAddedAddressForDelivery) 
      setSelectedAddress(newAddedAddressForDelivery)
  }, [userProfileData, newAddedAddressForDelivery]);

  return (
    <div>
      <div>
        { 
          addGuestAddress ?
            <VisitorDeliveryAddress
              handleLocation={handleLocation}
              isItMobile={isItMobile}
              checkNearestStoreLocation={checkNearestStoreLocation}
              saveSelectedOrderType={saveSelectedOrderType}
              isFindingNearest={isFindingNearest}
              isNearestStoreAvailable={isNearestStoreAvailable}
              setNewAddedAddressForDelivery={setNewAddedAddressForDelivery}
              closeNewAddressForm={closeNewAddressForm}
              showOrderTypeGrid={showOrderTypeGrid}
            />
            :
            <UserAddressDropdown
                isGuestAddressSelected={isGuestAddressSelected}
                userAddresses={userAddresses}
                newAddedAddressForDelivery={newAddedAddressForDelivery}
                isNearestStoreAvailable={isNearestStoreAvailable}
                handleFormSubmission={handleFormSubmission}
                setAddress={setAddress}
                checkNearestStoreLocation={checkNearestStoreLocation}
                selectedAddress={selectedAddress}
                setShowOrderTypeGrid={setShowOrderTypeGrid}
                setIsNearestStoreAvailable={setIsNearestStoreAvailable}
                setAddGuestAddress={setAddGuestAddress}
                isFindingNearest={isFindingNearest}
            />
        }
      </div>
    </div>
  );
};
