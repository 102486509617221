import {ADD_ONS, BY_DEFAULT, DELIVERY_ORDER_TYPE, PICK_UP_ORDER_TYPE} from "../../constants";
import { IByDefaultSelectedItemModifiers, IItemModifiers, IItemsModifierObject } from "../../models/order";
import { removeDuplicateModifiers } from "../../redux/utility";
import * as CONSTANT from "../../constants";
import { calculatePriceForItem } from "../../priceCalculation/calculatePrice";
import { Codes, IItemsRequiredModifierGroups } from "../../models/item.model";
import _clonedeep from 'lodash.clonedeep'

export const verifyStatus = (item, key) => {
  return !(item[key] ? item[key][0].status : "in-active");
};

export const availableForSelectedOrderType = (item, orderType) => {
  if (orderType == PICK_UP_ORDER_TYPE)
    return item.is_pickup
  else if (orderType == DELIVERY_ORDER_TYPE)
    return item.is_delivery
  else
    return true
}

export const availableModifiers = (item:any) => {
  return item
  ? item.core_modifiers
      .filter((mod) => verifyStatus(mod, CONSTANT.MODIFIER_LOCATION))
  : "";
}

export const unavailableModifiers = (item:any) => {
  return item
  ? item.core_modifiers
      .filter((mod) => !verifyStatus(mod, CONSTANT.MODIFIER_LOCATION))
  : "";
}

export const unavailableModifiersList = (item) => {
  return item
    ? item.core_modifiers
        .filter((mod) => !verifyStatus(mod, CONSTANT.MODIFIER_LOCATION))
        .map((mod) => mod.name)
        .reduce((previousValue, currentValue, i, arr) => {
          if (i === 0) return currentValue;
          else if (i === arr.length - 1)
            return `${previousValue} & ${currentValue}`;
          else return `${previousValue}, ${currentValue}`;
        }, "")
    : "";
};

export const getCardFormattedPrice = (
  ingredientToShow,
  ingredient,
  selected
) => {
  const actualPrice =
    ingredientToShow.price - ingredient.price > 0
      ? ingredientToShow.price - ingredient.price
      : 0;
  if (selected.quantity && selected.quantity > 1) return ingredientToShow.price;
  else return actualPrice;
};
export const uniqueIngredients = (item) =>
  item
    ? [
        ...item.core_modifiers,
        ...item.items_required_modifier_groups
          .map((group) => group.required_modifiers_groups_modifiers)
          .flat(),
      ]
    : [];

export const getOrderButtonState = (item, order) => {
  const modifiers = order.modifiers.find(
    (mod) => mod.modifier_id == item.id
  )?.modifiers;
  if (!modifiers) return false;
  return (
    item.items_required_modifier_groups.filter((group) =>
      modifiers.find((mod) => mod.modifier_group_id === group.id && !mod.core)
    ).length !== item.items_required_modifier_groups.length
  );
};

export const sortedDressings = (group, selectedModifiers) => {
  return group.required_modifiers_groups_modifiers.sort((dressing) => {
    if (
      selectedModifiers.find(
        (modifier) => modifier.modifier_group_id === group.id
      )
    ) {
      return group.required_modifiers_groups_modifiers.indexOf(dressing) !==
        1 &&
        selectedModifiers.find(
          (mod) => mod.modifier_id === dressing.id && !mod.core
        )
        ? -1
        : +1;
    }
    return 0;
  });
};

export const filterArraysBasedOnGroupId = (arrays:any[], groupId:number) => {
  let filteredModifiers = [];
  for (let index = 0; index < arrays.length; index++) {
    const modifiers = arrays[index]?.filter((modifier) => modifier.modifier_group_id === groupId)
    filteredModifiers.push.apply(filteredModifiers, modifiers)
  }
  return filteredModifiers
}

export const createPayloadForSelectedSingleItemModifier = (selectedModifiers: IByDefaultSelectedItemModifiers) => {
  let payload = null;
    payload = {
      modifier_id: selectedModifiers?.id,
      modifier_name: selectedModifiers?.name,
      display_price: selectedModifiers?.price,
      modifier_calories: selectedModifiers?.calories,
      brink_id: selectedModifiers?.brink_modifier_id,
      core:true,
      // quantity: 1,
      modifier_group_min:selectedModifiers?.modifier_group_min,
      max:selectedModifiers?.max,
      type: BY_DEFAULT,
      modifier_group_id: selectedModifiers?.modifier_group_id,
      modifier_group_max: selectedModifiers?.modifier_group_max,
      modifier_type: ADD_ONS,
    }
    if(selectedModifiers?.max > 1){
      payload.quantity = 1
    }
    return payload
}

export const modifierGroups = (item:any, unrepeatedIngredients:any) => {
  return removeDuplicateModifiers(
    item?.items_modifier_groups.filter((group) => group.is_customizable_menu),
    unrepeatedIngredients
  );
};

export const getModifierGroupType = (item:any, groupId: number) => {
  const isAddOn = item.items_modifier_groups.some((group) => group.id === groupId)
  if( isAddOn ) return CONSTANT.ADD_ONS
  const isRequired = item.items_required_modifier_groups.some((group) => group.id === groupId)
  if( isRequired ) return CONSTANT.REQUIRED_MODIFIERS
  return CONSTANT.COMPLIMENTARY_MODIFIER
};

const getRequiredGroupBasePrice = ( groupId: number, item ) => {
  if( !item ) return 0;
  const group = item?.items_required_modifier_groups ?? []
  const groupItem = group.find((groups) => groups.id === groupId)
  if( groupItem && groupItem.base ) return  groupItem.base;
  return 0;
}

const getAddOnsGroupBasePrice = ( groupId: number, item ) => {
  if( !item ) return 0;
  const group = item?.items_modifier_groups ?? []
  const groupItem = group.find((groups) => groups.id === groupId)
  if( groupItem && groupItem.base ) return  groupItem.base;
  return 0;
}

export const createPayloadToSync = (modifier: any, item) => {
  const available = verifyStatus(modifier, CONSTANT.MODIFIER_LOCATION)
  let payload = null;
  payload = {
    modifier_id: modifier.id,
    modifier_name: modifier.name,
    display_price: modifier.price,
    modifier_calories: modifier.calories,
    brink_id: modifier.brink_modifier_id,
    type: available ? CONSTANT.BY_DEFAULT : CONSTANT.DECREASE,
    code: available ? Codes.ADD : Codes.NO,
    modifier_group_id: modifier.modifier_group_id,
    modifier_group_min: modifier.modifier_group_min,
    modifier_group_max: modifier.modifier_group_max,
    modifier_group_extendable_limit: modifier.modifier_group_extendable_limit,
    max: modifier.max,
    is_selected: available ? modifier.is_selected: false,
    quantity: available ? (modifier.quantity ?? 1) : 0,
    core:true,
    modifier_type: modifier.modifier_type ?? getModifierGroupType(item, modifier.modifier_group_id )
  }
  if( payload.modifier_type === CONSTANT.COMPLIMENTARY_MODIFIER ) payload.complimentary_modifier = true 
  if( modifier.max === 1 ) payload.core = true 
  return payload
}


/**
 * Handle Edit Item Selection for required, other and complimentary modifiers
 */
export const processEditItemModifiers = async (order: any, item, itemType = CONSTANT.SINGLE_ITEM, itemID = 1) => {
  let requiredIngredients : any = _clonedeep(order?.required_modifiers ?? []);
  if(requiredIngredients.length){
    requiredIngredients = sortBasedOnPriceByModifierGroups(requiredIngredients)
  }
  let addOnIngredients : any = _clonedeep(order?.other_modifiers ?? []);
  if(addOnIngredients.length){
    addOnIngredients = sortBasedOnPriceByModifierGroups(addOnIngredients)
  }
  let complementaryIngredients : any = order?.complementary_modifiers ? flatten(order?.complementary_modifiers) : []
  
  let coreRelatedIngredients : any = _clonedeep(order?.core_related_modifiers ?? []);
  if(coreRelatedIngredients.length){
    coreRelatedIngredients = sortBasedOnPriceByModifierGroups(coreRelatedIngredients)
  }
  let ingredients = requiredIngredients.concat(addOnIngredients,complementaryIngredients,coreRelatedIngredients)
  for (let i = 0; i < ingredients.length; i++) {
    if( ingredients[i].modifier_location && verifyStatus(ingredients[i], CONSTANT.MODIFIER_LOCATION) ) {
      let data : any = {}
      let id: any = itemID
      let base = 0;
      data = { 
        ...ingredients[i],
        modifier_id: ingredients[i].modifier_id,
        modifier_name: ingredients[i].modifier_name ?? ingredients[i].name,
        display_price: ingredients[i].price,
        modifier_calories: ingredients[i].calories,
        brink_id: ingredients[i].brink_id,
        code: ingredients[i].code,
        extendableLimitValue: ingredients[i].modifier_group_extendable_limit,
        modifier_type: setModifierTypeForBucket(ingredients[i].modifier_type),
        core: ingredients[i].max === 1 && ingredients[i].is_selected ? true : false,
        is_selected: ingredients[i].is_selected,
        quantity: ingredients[i].quantity,
        modifier_group_id: ingredients[i].modifier_group_id,
        modifier_group_base: ingredients[i].modifier_group_base,
        image: `${CONSTANT.REACT_APP_CLOUD_FRONT}/Modifiers/${ingredients[i]?.modifier_id}/modifier-${ingredients[i]?.modifier_id}.webp`
      }
      //Add Base Price in required
      if(  data.modifier_type === CONSTANT.REQUIRED_MODIFIERS && item ) {
        base = getRequiredGroupBasePrice(data.modifier_group_id, item)
        data.modifier_group_base = base
      }
      //Add Base Price in AddOns
      if(  data.modifier_type === CONSTANT.ADD_ONS && item ) {
        base = getAddOnsGroupBasePrice(data.modifier_group_id, item)
        data.modifier_group_base = base
      }

      //In case of Complimentary 
      if( ingredients[i].modifier_type === CONSTANT.COMPLIMENTARY_MODIFIER) {
        data.complimentary_modifier = true
        id = itemType === CONSTANT.SINGLE_ITEM ? 2 : itemType === CONSTANT.KIDS_COMBO_ITEM ?  itemID : 3
      }
    
      let iterations = data.quantity > 0 ? data.quantity : 1
      for (let i = 0; i < iterations; i++) {
      let tempQuantity = 0;
      data.type = setActionType(data.code, data?.max, data?.is_selected, data?.modifier_type)
      let type = data.type
      if( data.code === CONSTANT.NO ) tempQuantity = 0
      else tempQuantity = i + 1

      if( iterations > 1 ){
        if (data.is_selected && i === 0 ) data.type = CONSTANT.BY_DEFAULT
      }
      calculatePriceForItem({currentModifier:{...data, quantity : tempQuantity}, item_id:id});
      data.type = type
    }
  }

  }
  return true;
}

const setModifierTypeForBucket = ( type : string ) => {
  if(type === CONSTANT.OTHER_MODIFIERS || type === CONSTANT.CORE_RELATED_MODIFIERS ) return CONSTANT.ADD_ONS
  return type;
}

const setActionType = ( code : string = '', max: number, isSelected = false, modifier_type = '' ) => {
  if( code === CONSTANT.ADD ) {
    if(modifier_type === CONSTANT.COMPLIMENTARY_MODIFIER && max <= 1) return isSelected ? CONSTANT.BY_DEFAULT : CONSTANT.INCREASE
    if( max > 1 ) return CONSTANT.INCREASE
    return isSelected ? CONSTANT.BY_DEFAULT : CONSTANT.SELECTED
  }
  return CONSTANT.DECREASE
}

export function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}

const ifCoreRelatedAndNotGreen = ( modifier ) =>{
  return modifier.treat_as === CONSTANT.CORE_RELATED && !( modifier.modifier_group_max === 1 && modifier.modifier_group_min === 1 && modifier.extendableLimitValue === 1)
}

export const createOrderPayloadModifiers = ( payload, isCombo ) => {
  payload.modifiers?.forEach((element) => {
    if (!element.display_price) element.display_price = 0;
    element.modifier_size = element.size
    if (!(element.quantity >= 0)) element.quantity = 1;
    element.is_item = isCombo && !element.complimentary_modifier ? true : false;
    if( ifCoreRelatedAndNotGreen(element) ) {
      element.modifier_type = CONSTANT.CORE_RELATED_MODIFIERS
    }
    element.modifiers?.forEach((element) => {
      if (!element.display_price) element.display_price = 0;
      element.is_item = false;
      if( ifCoreRelatedAndNotGreen(element)  ) {
        element.modifier_type = CONSTANT.CORE_RELATED_MODIFIERS
      }
    });
  });
  return payload;
}

export const isItSubstituteGroup = (requiredModifierGroup:IItemsRequiredModifierGroups[]) => {
  if (requiredModifierGroup &&
    requiredModifierGroup.length > 0) {
    if (requiredModifierGroup[0]?.min === 1 && requiredModifierGroup[0]?.max === 1 && requiredModifierGroup[0]?.extendable_limit === 1) {
      return true
    }
  }
  return false
}

export const isItSubstituteGroupFromMultipleRequired = (
  requiredModifierGroup: IItemsRequiredModifierGroups,
) => {
  if (requiredModifierGroup) {
    if (
      requiredModifierGroup.min === 1 &&
      requiredModifierGroup.max === 1 &&
      requiredModifierGroup.extendable_limit === 1
    ) {
      return true;
    }
  }
  return false;
};

export const checkIfUserAddSecondItemAfterOne = (order) => {
  let isUserAddingSecondItem = false;
  for (let index = 0; index < order?.modifiers.length - 1; index++) {
    const currentItem = order?.modifiers[index];
    if(currentItem?.is_selected) isUserAddingSecondItem = true;
  }
  return isUserAddingSecondItem
}

export const checkIfUserAddAllItems = (order) => {
  let isUserAddingSecondItem = [];
  for (let index = 0; index < order?.modifiers.length - 1; index++) {
    const currentItem = order?.modifiers[index];
    if(currentItem?.is_selected) isUserAddingSecondItem.push(true);
  }
  const isThreeSelected = isUserAddingSecondItem.filter((val) => val)
  return isThreeSelected.length > 1 ? true : false
}

export const sortBasedOnPriceByModifierGroups = (modifierGroups:any) => {
  const groups = _clonedeep(modifierGroups)
  return groups.sort((a, b) => {
    if (a.modifier_group_id < b.modifier_group_id) return -1;
    if (a.modifier_group_id > b.modifier_group_id) return 1;
    if (a.price > b.price) return -1;
    if (a.price < b.price) return 1;
    return 0;
  });
}