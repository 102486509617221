import { useMutation } from "react-query";
import { locationMenuService } from "../../services";

const getUtcTimeZone = async () => {
  const response = await locationMenuService.getUtcTimeZoneTime();
  return response?.data?.data;
};

export default function useUtcTimeZone() {
    return useMutation(() =>
        getUtcTimeZone()
    );
}
