import { UPPERCASE_ADD, UPPERCASE_SUB, UPPERCASE_MULTI, UPPERCASE_DVISION } from "../constants";

export enum quantityCounterOperations {
  increase = "INCREASE",
  decrease = "DECREASE",
}

export interface suggestedItem {
  name: string;
  full_calories: string;
  id: number;
  price: number;
  image: string;
  title?: string;
  image_url?: string
  is_active_in_cart?: boolean
}


export interface IOfferItem {
  id: number
  name: string
  loyalty_offer_id: string
  loyalty_root_offer_id: string
  expiration_date: string
  expires_in_days: number
  expiration: string
  redirect_to_item: boolean
  redirect_to_category: boolean
  redirect_item_id: number
  redirect_category_id: number
  redirect_category_name: string
  image_url: string
  data: IOfferItemDetails
  in_cart?: boolean;
  cart_item_index: number;
  redeemed_by_offer_discount?: number;
}

export interface IOfferEligibilityCriteria {
  eligibleSubTotalRange?: {
    minimum: number; 
    maximum: number ;
  };
  eligibleLocations?: string[];
  eligibleItems?: { name: string; size: string }[];
  failedCriteria: string;
}

export interface IOfferItemDetails {
  offer_id: string
  reward_store_offer_id: string
  root_offer_id: string
  reward_store_id: string
  price: number
  uri: string
  category_id: string
  category_name: string
  content_type: number
  terms: string
  offer_status: number
  offer_inventory_restrictions: any[]
  offer_type: string
  discount_amount?: number
  percent_discount?: number
  allow_overage: boolean
  buy_count: number
  max_recurrence: number
  item_price_affinity: number
  retailer_id: string
  title: string
  status: number
  acquisition_start_date: string
  time_zone_settings: string
  validity_period: number
  validity_unit: string
  reward_store: boolean
  requires_extended_data: boolean
  check_level: boolean
  parent_offer_ids: any[]
  offer_media: any[]
  start_date: string
  valid_after_end_date: boolean
  all_items_eligible: boolean
  offer_catalog_restrictions: any[]
  offer_purchase_restrictions: any[]
  offer_redemption_restrictions: IOfferRedemptionRestrictions
  offer_location_restrictions: any[]
  eligible_items: IEligibleItems
  excluded_items: any[]
  eligible_items_ids: string[]
  redirect_to_item_id: boolean
  redirect_to_category_id: boolean
  redirect_item_id: number
  redirect_item_size: string
  redirect_category_id: number
  redirect_category_name: string
  image_url: string
  eligible_locations: number[];
  free_delivery?: boolean;
}

interface IOfferRedemptionRestrictions {
  check_amount_restriction: ICheckAmountRestriction
}

export interface ICheckAmountRestriction {
  minimum_check_amount: number
  maximum_check_amount: number
  type: string
  id: string
  offer_id: string
}

export interface IEligibleItems {
  [key: string]: IEligibleItem
}
export interface IEligibleItem {
  id: string
  name: string
  externalIds: any[]
  itemId: string
  entityType: number
  isModifier: boolean
  pulseItemId: number
  pulseItemName: string
  pulseCategoryId: number
  pulseCategoryName: string
  pulseItemSize: string
  pulseBrinkId: string
  pulseItemImageUrl: string
  pulseItemPoints: number
  pulseItemPrice: number
  sessionMItemIdKey: string
  pulseItemIdKey: string
  discount_amount: number
}
export interface rewardItem {
  name: string;
  image: string;
  dueDate: string;
  id: number;
  menu_id: number,
  item_id: string | number;
  category_id?: number | string;
}

export interface cartItem {
  redemption_by_points:number;
  name: string;
  id: string | number;
  item_id: string | number;
  price: number;
  display_price?: number;
  base_price?: number;
  discount_amount: number;
  discount: number;
  gross_price?:number;
  size?: string;
  item_size?: string;
  isRedeemed?: boolean;
  set_id?: string | number;
  category_id?: number | string;
  free?: boolean;
  quantity?: number;
  index?: number;
  available?: boolean;
  unavailable_modifiers?: string[];
  auto_redeemed? : boolean;
  item_type: string;
  isComboItem? : boolean;
  modifiers?: ICartModifier[];
  reward_id?:string;
  cart_directly?:boolean
  category_name?: string;
  single_size:boolean;
  recipient_name?:string
  redeem_points?: string;
  reward_redeemable?: boolean
  reward_redeemed?: boolean
  is_redeemed_by_offer?: boolean;
  redeemed_by_offer_discount? : number;
  description?: string;
  isTry2Combo?:string;
  isKidsCombo?:string;
}

export interface ICartModifier {
  id: number;
  modifier_name:string;
  order_item_id: number;
  modifier_id: number;
  modifier_group_id: number;
  code: string;
  sub_with_id: string;
  brink_modifier_group_id:number;
  parent_id: number;
  brink_id: number;
  display_price: number;
  gross_price:number;
  is_seasonal_item: any,
  created_at: Date;
  updated_at: Date;
  modifiers: any;
  size?: string;
  quantity?: number;
  is_item?: boolean;
  name?: string;
  available?: boolean;
  unavailable_modifiers?: string[];
  modifier_type?: string
}

export interface IItemTreeItem {
  id: number[];
  order_id: number;
  item_id: number;
  brink_id: number;
  display_price: number;
  gross_price: number;
  is_seasonal_item: any;
  created_at: Date;
  updated_at: Date;
  quantity: number;
  set_id: number;
  category_id: number;
  name: string;
  special_request: any;
  free: number;
  reward_id: number;
  disabled: number;
  size: string;
  points: any;
  category_name: string;
  from_category: number;
  modifiers: ICartModifier[];
  price: number;
  isUnavailable: boolean;
}

export interface IItemTree {
  [key: string] : IItemTreeItem[];
}

export interface ICartTree {
  itemTree: IItemTree,
  comboItemTree: any,
  index: number
}

export type dmasOperationType =
  | typeof UPPERCASE_ADD
  | typeof UPPERCASE_SUB
  | typeof UPPERCASE_DVISION
  | typeof UPPERCASE_MULTI;