import {useState} from "react";
import {IGetCartPayload} from "../models/order.model";
import {DELIVERY_DESTINATION_ID, DELIVERY_ORDER_TYPE, IN_STORE_DESTINATION_ID, ROUTE_CHECKOUT} from "../constants";
import {getCart} from "../react-query-hooks/Cart/useGetCartItems";
import {clearCart, updateCartOrder} from "../redux/slices/cartSlice";
import {useAppDispatch, useAppSelector} from "../redux/store/store";
import {useAuthState} from "../context/UserAuthentication";
import useRedeemOffer from "./useRedeemOffer";
import { IOfferItem } from "../models/cart.model";

const useSyncCart = () => {
    const dispatch = useAppDispatch();
    const { authInfo } = useAuthState();
    const {redeemOffer, removeOffer, clearAppliedOffer} = useRedeemOffer();

    const cart = useAppSelector(state => state.cart);

    const [loading, setLoading] = useState<boolean>(false);

    const syncCart = async (location_id: string, persistOffer?: boolean, selectedOfferItem?: IOfferItem, allOffers?: IOfferItem[],) => {
        setLoading(true);
        try {
            if( !authInfo.userId ) return;
            const payload : IGetCartPayload = {
                location_id: location_id,
                customer_id: authInfo.userId
            }

            if( cart?.orderType ){
                payload.order_type = cart?.orderType === DELIVERY_ORDER_TYPE ? DELIVERY_DESTINATION_ID : IN_STORE_DESTINATION_ID
            }
            if (persistOffer) {
                payload.persist_offer = true;
            }
            const response = await getCart(payload)
            if( response && response.items )  {
                await dispatch(updateCartOrder(response));
                if (allOffers && !selectedOfferItem) {
                    selectedOfferItem = allOffers?.find(
                      (offer: IOfferItem) =>
                        response.root_offer_id === offer.loyalty_offer_id || response.root_offer_id === offer.loyalty_root_offer_id,
                    );
                  }
                if(selectedOfferItem)
                    {
                        selectedOfferItem.in_cart = false;
                        const { failedOfferCriteria } = redeemOffer({
                        offerItem: selectedOfferItem,
                        cartItems: response.items,
                        selectedOfferItem, 
                        rewardsDiscountedSubTotal: cart.rewardsDiscountedSubTotal
                        })
                        if (failedOfferCriteria){
                            clearAppliedOffer();
                            removeOffer(selectedOfferItem, true);
                        }
                    }
            }
            else {
                await dispatch(clearCart())
            }
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }
    return {syncCart, loading}
}

export default useSyncCart