import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import styleClasses from "../../../containers/account/accounts.module.scss";
import { CustomModal } from "../../../components/Modal/CustomModal";
import PaymentRecord from "../../Forms/PaymentRecordForm";
import NoStoredPaymentsBanner from "./noStoredPaymentBanner/noStoredPaymentBanner";
import StoredPaymentCard from "./storedPaymentCard/storedPaymentCard";
import useUpdatePaymentMethod from "../../../react-query-hooks/useUpdatePaymentMethod";
import { Toast_Func } from "../../../helpers/toast.helper";
import StoredPaymentsListing from "./StoredPaymentsListing";
import { useQueryClient } from "react-query";
import useProfile from "../../../react-query-hooks/useProfile";
import StripePaymentElement from "../../../containers/order/PaymentMethod/StripePaymentElement";

interface StoredPaymentsProps {data: any, paymentRecords: any}

const StoredPayments: React.FunctionComponent<StoredPaymentsProps> = ({data: userProfileData, paymentRecords}) => {

  const { mutateAsync } = useUpdatePaymentMethod();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [editRecordId, setEditRecordId] = useState(null);
  let queryClient = useQueryClient();
  const [defaultPayment, setDefaultPayment] = useState(null);
  const handleEdit = (id) => {
    setEditRecordId(id);
    setOpenPaymentModal(true);
  };
  const closeModal = () => {
    setOpenPaymentModal(false);
  };
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
useEffect(()=>{
  if(!defaultPayment){
    setDefaultPayment(paymentRecords.find(rec=>rec.is_default==true))
  }
},[paymentRecords])
  useEffect(() => {
    !openPaymentModal && editRecordId && setEditRecordId(null);
  }, [openPaymentModal]);
  const handleChangeDefault = async (id) => {
    const changedRecord = paymentRecords.find((rec) => rec.id == id);
    setDefaultPayment(changedRecord);
    return mutateAsync(
      {
        newPaymentMethod: {...changedRecord,is_default:true, customer_id: userProfileData.id },
        recordID: id,
      },
      {
        onSuccess: async(data) => {
          setDefaultPayment({...changedRecord,is_default:true})
          queryClient.invalidateQueries(["paymentMethod"]);
          queryClient.invalidateQueries(["profile"]);
          setCloseConfirmationModal(true)
          Toast_Func({ status: true, message: "Default updated successfully" });
        },
        onError: (error) => {
          Toast_Func({ status: false, message: error });
          setDefaultPayment(paymentRecords.find(rec=>rec.is_default==true))
        },
      }
    );
  };
  return (
    <>
      <StoredPaymentsListing
        closeConfirmationModal={closeConfirmationModal}
        paymentRecords={paymentRecords}
        defaultPayment={defaultPayment}
        handleChangeDefault={handleChangeDefault}
        handleEdit={handleEdit}
        setOpenPaymentModal={setOpenPaymentModal}
      />
      <CustomModal
        showModal={openPaymentModal}
        closeModal={() => setOpenPaymentModal(false)}
        title={`Payment`}
      >
         {/*<PaymentRecord record={editRecordId} closeModal={closeModal} setDefaultPayment={setDefaultPayment} />*/}
          <StripePaymentElement isCheckout={false} closeProfileModal={closeModal}/>
      </CustomModal>
    </>
  );
};

export default StoredPayments;
