import React, { useState } from "react";
import { GUEST_USER } from "../../constants";
import { getUser } from "../../helpers/helperMethods";

interface ICheckbox {
    setIsMessageSubscription: (boolean) => void;
    isCreateAccountPage?: boolean;
    color?:string
}

const SmsCheckbox: React.FC <ICheckbox> = ({setIsMessageSubscription, isCreateAccountPage = false, color}) => {
    const [isChecked, setIsChecked] = useState(true);
    const user = getUser();
  return (
    <>
      {isCreateAccountPage || (user.type !== GUEST_USER && !user.subscribe_to_sms_marketing) ? (
        <div className="form-group theme_check_boxes">
          <input
            name="marketing_email_subscription"
            type="checkbox"
            id="checkbox_1"
            onChange={(e) => {
              setIsChecked(!isChecked);
              setIsMessageSubscription(!isChecked);
            //   handleChange(e);
            }}
            checked={isChecked}
          />
          <label htmlFor="checkbox_1" className={`text-start d-flex align-items-start pt-3 ${color}`}>
            <span className="box_wrap"></span>
              Nourish the Good Life with exclusive Café Zupas rewards, updates,
             and seasonal menus sent right to you via sms.
          </label>
        </div>
      ) : null}
    </>
  );
};

export default SmsCheckbox;
