import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import * as CONSTANT from "../../constants";
import useGetRewards from "../../react-query-hooks/useGetRewards";
import useProfile from "../../react-query-hooks/useProfile";
import styleClasses from "../Header/Header.module.scss";
import { logout, useAuthState } from "../../context/UserAuthentication";
import UploadRewardsBarcode from "../UserSelections/uploadRewards/uploadRewardsBarcode";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/store/store";
import {
  resetRedeemedItemContext,
  resetRedeemedOrderContex,
} from "../../redux/slices/itemSlice";
import { useQueryClient } from "react-query";
import { useStoreLocation } from "../../context/StoreLocation";
import { shouldRelease } from "../../helpers/helperMethods";
import { customerBusinessService } from "../../services";
import LoaderButton from "../../components/Buttons/Button";
import { IRewardItem } from "../../models/rewards.model";
import DynamicContactInfoForm from "../Forms/DynamicContactInfoForm";
import { UserCircleFilledIcon } from "../../images/svgIcons/svg";
import axios from "axios";

const UserName = () => {
  const { dispatch, authInfo } = useAuthState();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showDynamicContentModal, setShowDynamicContentModal] = useState(false);
  const queryClient = useQueryClient();
  let history = useHistory();
  const { data: userProfileData, isFetching } = useProfile(
    authInfo.userId,
    authInfo.type
  );
  useEffect(() => {
    if (userProfileData?.update_required) {
      setShowDynamicContentModal(true);
    }
  }, [userProfileData]);

  const handleCloseDynamicContentModal = () =>
    setShowDynamicContentModal(false);

  const cartDispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const loyalityPoints = useAppSelector(
    (state) => state.Items.item.loyalityPoints
  );
  const redeemedItemDispatch = useAppDispatch();
  const { locationInfo } = useStoreLocation();

  const { data: allRewards = { rewards: [] } } = useGetRewards(
    authInfo?.userId,
    locationInfo.selectedStore?.id,
    1,
    1,
    1,
    authInfo?.type
  );
  let validRewards: IRewardItem[] = allRewards?.rewards.filter((reward) => {
    return !reward.is_redeemed && !reward.is_expired;
  });
  const closeModalHandler = () => {
    setShowModal(false);
  };
  const handleLogout = async () => {
    setIsSubmitting(true);
    redeemedItemDispatch(resetRedeemedItemContext());
    redeemedItemDispatch(resetRedeemedOrderContex());
    await axios.post(
      `${CONSTANT.CUSTOMER_MICRO_SERVICE}/logout`,
      { is_catering: true },
      {
        headers: {
          "Refresh-token": authInfo?.refresh_token
        }
      }
    );
    logout(dispatch, cartDispatch);
    queryClient.removeQueries();
    setIsSubmitting(false);
    history.push("/");
  };

  return (
    <>
      <div
        className={`${styleClasses.app_user_links} app_user_links d-none d-md-flex align-items-end`}
      >
        <Dropdown className="type-link-dropdown">
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="d-inline-flex align-items-center"
            aria-label="Name"
            aria-labelledby="labeldiv"
          >
            <UserCircleFilledIcon />
            {userProfileData && (
              <div className="d-flex flex-column align-items-start">
                <span className="text-uppercase f-s12 lh-normal ls-2">Welcome,</span>{" "}
                <span className="f-s16 lh-normal ls-1"> {userProfileData?.first_name?.toUpperCase()}</span>
              </div>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <h6 className="h-18 font-Cls text-center overflow-hidden text-break">
              {userProfileData?.full_name}
            </h6>
            {shouldRelease("Features", "redeem") && (
              <Dropdown.Item
                as={Link}
                to={CONSTANT.ROUTE_REWARDS_REDEEM}
                className="lh-normal  clr-lt-black d-flex align-items-center justify-content-center"
              >
                <span className="number me-1 f-s18">{loyalityPoints}</span>
                <span className="f-s18">
                  {loyalityPoints > 1 ? "POINTS" : "POINT"}
                </span>
                <span className="number me-1">
                  <span className="f-s12 dot-style"></span>
                  <span className="f-s18 me-1">{validRewards?.length}</span>
                </span>
                <span className="d-inline-flex align-items-center f-s18">
                  {validRewards?.length > 1 ? "REWARDS" : "REWARD"}{" "}
                  <img
                    src={require("../../images/arrow-right.svg")}
                    className="img-fluid ms-1"
                    alt="icon"
                  />
                </span>
              </Dropdown.Item>
            )}
            <Dropdown.Item
              as={Link}
              to={CONSTANT.ROUTE_PROFILE_DETAILS}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={`${CONSTANT.REACT_APP_CLOUD_FRONT}/images/icons/UserCircle.svg`}
                className="img-fluid me-1"
                alt="user circle"
              />{" "}
              Profile & Preferences
            </Dropdown.Item>
            <Dropdown.Item href={CONSTANT.ROUTE_REWARDS_REDEEM}>
              Loyalty & Rewards
            </Dropdown.Item>
            <Dropdown.Item href={CONSTANT.ROUTE_FAQ}>Rewards FAQ</Dropdown.Item>
            <LoaderButton
              onClick={handleLogout}
              type="button"
              className="btn dark-grey f-s14"
              loading={isSubmitting}
              disabled={isSubmitting}
              isItSmall={true}
            >
              SIGN OUT
            </LoaderButton>
            <div className="d-flex cms-pages justify-content-center">
              <Dropdown.Item as={Link} to={CONSTANT.TERMS_ROUTE}>
                Terms and Conditions
              </Dropdown.Item>
              <span className="d-inline-block px-2">and</span>
              <Dropdown.Item as={Link} to={CONSTANT.PRIVACY_POLICY_ROUTE}>
                Privacy Policy
              </Dropdown.Item>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Modal
        show={showModal}
        onHide={closeModalHandler}
        centered
        backdropClassName="backdrop-responsive-100vmax"
        className="theme-modal-style app_container_wrap no-padding border-radius-15 no-border xs-modal responsive-100vmax receipt-modal"
      >
        <Modal.Header
          className="pt-3 pt-md-3 mt-md-2 ps-0 pb-2"
          id="close_button2"
        >
          <button
            type="button"
            className="btn btn-custom back-arrow-btn f-s14 px-0 mb-0"
            onClick={closeModalHandler}
          >
            Back
          </button>
        </Modal.Header>
        <Modal.Body className="text-center px-4 pb-4">
          <UploadRewardsBarcode closeModalHandler={closeModalHandler} />
        </Modal.Body>
      </Modal>
      {!isFetching && (
        <Modal
          show={showDynamicContentModal}
          className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
          centered
        >
          <Modal.Header>
            <h5 className="f-s22 font-Cls text-center  pt-4  mb-0 text-capitalize w-100">
              you're Almost There!
            </h5>
          </Modal.Header>
          <Modal.Body className={"px-lg-5 pt-0"}>
            <DynamicContactInfoForm
              userStatusData={userProfileData}
              handleContentChange={handleCloseDynamicContentModal}
              closeModal={handleCloseDynamicContentModal}
              isSignInFlow={true}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default UserName;
